import React from "react";

import "./App.css";
import { ZoomMtg } from "@zoomus/websdk";
import * as CryptoJS from "crypto-js";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { isMobile } from 'react-device-detect';

function App() {
  const searchParams = new URLSearchParams(document.location.search);

  const eventInformation = searchParams.get("info");
  const decrypted = CryptoJS.AES.decrypt(
    eventInformation
      .replaceAll("xMl3Jk", "+")
      .replaceAll("Por21Ld", "/")
      .replaceAll("Ml32", "="),
    "secret"
  );

  React.useEffect(() => {

    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.17.0/lib", "/av");

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load("en-US");
    ZoomMtg.i18n.reload("en-US");

    return () => {
      ZoomMtg.leaveMeeting();
    };

  }, []);

  const resolvePayload = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

  const eventInfo = resolvePayload?.eventInfo;
  const meetingNumber = resolvePayload?.meetingNumber;
  const passWord = resolvePayload?.passWord;
  const role = resolvePayload?.type === "speaker" ? '1' : '0';
  const userName = resolvePayload?.userName;
  const userEmail = resolvePayload?.userEmail;

  const leaveUrl = `${process.env.REACT_APP_WEBAPP_URL}/thanks-for-participating`;
  // const sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;
  const sdkKey = 'xHieO9uETQuZdRoRwRGyPg';
  const sdkSecret = 'SAN57D3KJxTmqR6ZE2x5LbFlxoxoRrk7';

  const startMeetingClientView = async () => {

    ZoomMtg.generateSDKSignature({
      meetingNumber,
      role,
      sdkKey,
      sdkSecret,
      success: function (signature) {
        const rootMeeting = document.getElementById("zmmtg-root");
        if (rootMeeting && rootMeeting.style) rootMeeting.style.display = "block";
        ZoomMtg.init({
          leaveUrl: leaveUrl,
          success: function (success) {
            const previewText = document.querySelectorAll('.mini-layout-body-title');
            previewText.forEach(text => {
              if (text.textContent === 'Video Preview')
                text.style.display = "none"
            });
            console.log(success)
            ZoomMtg.join({
              meetingNumber,
              signature: signature.result,
              sdkKey,
              userName,
              userEmail,
              passWord,
              success: function (ok) {
                console.log(ok)
              },
              error: function (err) {
                console.log(err, 'aaaaaaaaaaaaaaaaaaaaaaaaa')
              }
            })
          },
          error: function (err) {
            console.log(err)
          }
        })
      },
      error: function (err) {
        console.log(err)
      }
    })
  };

  if (!eventInfo?.id)
    return (
      <div className="App">
        <main
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "40px",
          }}
        >
          <h3 style={{ margin: 0, lineHeight: "1.4" }}>
            There are no events going on in this room
          </h3>
        </main>
      </div>
    );

  return (
    <div className="App" style={{ display: "flex", height: '100vh', overflow: 'auto', WebkitOverflowScrolling: 'touch' }}>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{eventInfo?.name}</title>
      </Helmet>
      <main style={{ fontSize: isMobile ? '12px' : '14px' }}>
        {isMobile && (
          <h4 className="line-clamp">Event {eventInfo?.name}</h4>
        )}
        {!isMobile && (
          <h2 className="line-clamp">Event {eventInfo?.name}</h2>
        )}
        <p>
          <b>Type:</b> {eventInfo?.eventTypeName}{" "}
        </p>
        <p>
          <b>Date:</b>{" "}
          {eventInfo?.date ? dayjs(eventInfo?.date).format("DD-MM-YYYY") : ""}{" "}
        </p>
        <p>
          <b>Start time:</b> {eventInfo?.startTime}{" "}
        </p>
        <p>
          <b>End time:</b> {eventInfo?.endTime}{" "}
        </p>
        <p>
          <b>Venue:</b> {eventInfo?.venueName}{" "}
        </p>

        <button onClick={startMeetingClientView} disabled={!eventInfo?.id}>
          Join Meeting
        </button>
      </main>
    </div>
  );
}

export default App;
